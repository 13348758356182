<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar dirección</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">

        <!-- Providers -->
        <div>
          <label class="text-sm opacity-75">Proveedor*</label>
          <v-select :options="providers"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.provider" class="mb-4 md:mb-0" name="provider" id="provider"
                    label="businessName"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('provider')">{{ errors.first('provider') }}</span>
        </div>

        <!--Address-->
        <vs-input @blur="document.address= trimString(document.address)" label="Dirección*"
                  v-model="document.address"
                  class="w-full mt-5" name="address"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('address')">{{ errors.first('address') }}</span>

        <!-- Departments -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Departamento*</label>
          <v-select placeholder="Selecciona" v-validate="'required'" @input="document.province = {}"
                    label="nomDepartamento"
                    :options="departments" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.department" class="mb-4 md:mb-0" name="department"/>
          <span class="text-danger text-sm"
                v-show="errors.has('department')">{{ errors.first('department') }}</span>
        </div>

        <!-- Provinces -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Provincia*</label>
          <v-select placeholder="Selecciona" v-validate="'required'" @input="document.district = {}"
                    label="nomProvincia"
                    :options="filteredProvince" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.province" class="mb-4 md:mb-0" name="province"/>
          <span class="text-danger text-sm"
                v-show="errors.has('province')">{{ errors.first('province') }}</span>
        </div>

        <!-- Districts -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Distrito*</label>
          <v-select placeholder="Selecciona" v-validate="'required'" @input="getUbigeo" label="nomDistrito"
                    :options="filteredDistrict"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.district" class="mb-4 md:mb-0" name="district"/>
          <span class="text-danger text-sm"
                v-show="errors.has('district')">{{ errors.first('district') }}</span>
        </div>

        <!--Ubigeo-->
        <vs-input @blur="document.ubigeo= trimString(document.ubigeo)" label="Ubigeo*"
                  v-model="document.ubigeo"
                  class="w-full mt-5" name="ubigeo"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('ubigeo')">{{ errors.first('ubigeo') }}</span>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../../mixins/trimString'

import { db, auth, FieldValue } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    providers: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    provinces: {
      type: Array,
      required: true
    },
    districts: {
      type: Array,
      required: true
    },
    ubigeo: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        department: {},
        province: {},
        district: {}
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    filteredProvince () {
      return this.provinces.filter((province) => {
        return province.codDepartamento === this.document.department.codDepartamento
      }).sort((a, b) => {
        a.nomProvincia.localeCompare(b.nomProvincia)
      })
    },
    filteredDistrict () {
      return this.districts.filter((district) => {
        return district.codProvincia === this.document.province.codProvincia
      }).sort((a, b) => {
        a.nomDistrito.localeCompare(b.nomDistrito)
      })
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          // Update address
          let obj = {
            ...this.document
          }
          await db.collection('providers').doc(this.document.provider.id).collection('addresses').doc(this.document.id).update({
            ...obj,
            uid: auth.currentUser.uid,
            updatedAt: FieldValue.serverTimestamp()
          })
          this.$emit('closeSidebar')
          this.$emit('update', obj)
          this.$vs.notify({
            color: 'success',
            title: 'Dirección',
            text: 'Dirección actualizado correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Get ubigeo
     */
    getUbigeo () {
      if (this.document.district) {
        this.ubigeo.forEach((u) => {
          console.log(u.districts)
          console.log(u.department)
          console.log(u.district)
          if (u.districts.toUpperCase() === this.document.district.nomDistrito.toUpperCase() && u.province.toUpperCase() === this.document.province.nomProvincia.toUpperCase() && u.department.toUpperCase() === this.document.department.nomDepartamento.toUpperCase()) {
            this.document.ubigeo = u.ubigeo
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 450px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
